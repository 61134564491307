export default {
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      texts: "",
      screenWidth: 0,
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.subadminTexts[this.selectLanguage];
  },
  methods: {
    addSubadmin() {
      this.bLoading = true;

      const params = {
        sName: this.name,
        sLastname: this.lastname,
        sEmail: this.email,
        sPhoneNumber: this.phoneNumber,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/administrators`,
        params,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    close() {
      this.$store.commit("setDialogAddPermits", false);
      this.name = "";
    },
    emitClose() {
      this.$store.commit("setDialogAddPermits", false);
      this.name = "";
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogAddPermits() {
      return this.$store.state.dialogAddPermits;
    },
    validateForm() {
      return this.name.trim() !== "";
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.subadminTexts[this.selectLanguage];
      }
    },
    dialogAddPermits: function() {
      if (this.dialogAddPermits) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.subadminTexts[this.selectLanguage];
      }
    },
  },
};
